h2 {
  text-align: center;
}

p {
  text-align: justify;
}

h2, p {
  margin: 1rem;
}

p, a {
  font-family: "Roboto", sans-serif;
}

h1, h2, h3, h4 {
  font-family: "Montserrat", sans-serif;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.card {
  margin: 1rem;
  margin-bottom: 3rem;
  padding: 1rem;
  color: #000;
  text-align: center;
}
.card ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

breakpoint {
  display: block;
  position: relative;
  top: -10vh;
  scroll-snap-type: y mandatory;
}

@media screen and (min-height: 1200px) {
  p {
    font-size: x-large;
  }
}
.socialLogo {
  height: 2rem;
  filter: invert(1);
  object-fit: fill;
}

.larger {
  transform: scale(1.25);
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-primary {
  background-color: #6a0dad;
  border: solid #6a0dad;
}
.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
  background-color: #880fdd;
  border: solid #880fdd;
}

img {
  object-fit: scale-down;
}

.link {
  display: flex;
  justify-content: center;
  transition: 330ms;
}
.link:hover {
  transform: scale(1.2);
}

.boldTitle {
  font-weight: bold;
}

section {
  padding: 1.5rem;
  background-color: #333333;
  color: #fff;
}

.bg-invert {
  background-color: #ffd700;
  color: #000;
}
.bg-invert a {
  color: #fff;
}

footer {
  height: auto;
  padding: 1rem;
  background-color: #333333;
  color: #fff;
}

.carousel {
  margin-top: 2rem;
  max-height: 50vh;
  margin: 1rem;
  display: flex;
  align-items: center;
}
.carousel img {
  object-fit: scale-down;
  width: 100%;
  max-height: 45vh;
}

.sociallist ul {
  display: flex;
  flex-direction: row;
}
.sociallist ul li {
  margin-right: 1rem;
}

.footerMod {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.devAdd {
  font-size: xx-small;
}
.devAdd a {
  color: #fff;
  text-decoration: none;
}

.flexMod {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 1rem;
}

.flexCol {
  flex-direction: column;
}
.flexCol p {
  text-align: justify;
  margin: 1rem, 0rem;
}

.contact {
  padding: 1rem 0 1rem 0;
}
.contact form {
  margin-bottom: 2rem;
}

header {
  width: 100%;
  height: 10vh;
}

nav {
  min-height: 100%;
}
nav img {
  min-height: 60px;
  min-width: 60px;
  margin-right: 1rem;
  top: 0%;
  left: 0;
}

a {
  display: flex;
  align-items: center;
}
a h1 {
  font-size: 1.5rem;
}

.bg-primary {
  background-color: #333333 !important;
}

.home {
  background-color: transparent;
  background-image: url("../img/home_bg.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: color-burn;
  backdrop-filter: blur(50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
}
.home h1 {
  text-align: center;
}
.home img {
  max-height: 50vh;
  max-width: 100%;
  backdrop-filter: blur(3px);
  margin-bottom: 1rem;
}

h2 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

p {
  margin-bottom: 2rem;
}

