section
    //min-height: 90vh
    padding: 1.5rem
    background-color: $primary
    color: #fff
.bg-invert
    background-color: $secondary
    color: #000
    a
        color: #fff

footer
    height: auto
    padding: 1rem
    background-color: $primary
    color: #fff

.carousel
    margin-top: 2rem
    max-height: 50vh
    margin: 1rem
    display: flex
    align-items: center
    img
        object-fit: scale-down
        width: 100%
        max-height: 45vh

.sociallist ul
    display: flex
    flex-direction: row
    li
        margin-right: 1rem

.footerMod
    display: flex
    justify-content: space-between
    align-items: flex-end

.devAdd 
    font-size: xx-small
    a
        color: #fff
        text-decoration: none

.flexMod
    display: flex
    justify-content: center
    align-content: center
    margin-top: 1rem

.flexCol
    flex-direction: column
    p
        text-align: justify
        margin: 1rem, 0rem

.contact
    padding: 1rem 0 1rem 0
    form
        margin-bottom: 2rem