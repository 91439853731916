@import './variables.sass'
    
h2
    text-align: center

p
    text-align: justify


h2, p

    margin: 1rem

p, a
    font-family: $main-font

h1, h2, h3, h4
    font-family: $title-font




ul
    list-style: none
    margin: 0
    padding: 0

.card
    margin: 1rem
    margin-bottom: 3rem
    padding: 1rem
    color: #000
    text-align: center
    ul
        display: flex
        flex-direction: column
        justify-content: space-between

breakpoint
    display: block
    position: relative
    top: -10vh
    scroll-snap-type: y mandatory

@media screen and (min-height: $large)
    p
        font-size: x-large

.socialLogo
    height: 2rem
    filter: invert(1)
    object-fit: fill
.larger
    transform: scale(1.25)


.btn
    display: flex
    align-items: center
    justify-content: center
    &-primary
        background-color: $tertiary
        border: solid $tertiary
        &:hover, &:active, &:focus
            background-color: $tertiary-bright
            border: solid $tertiary-bright
        
            

                    

img
    object-fit: scale-down

.link
    display: flex
    justify-content: center
    transition: 330ms
    &:hover
        transform: scale(1.2)

 
.boldTitle
    font-weight: bold
