$primary: #333333
$secondary: #ffd700
$tertiary: #6a0dad
$tertiary-bright: #880fdd


$main-font: 'Roboto', sans-serif
$title-font: 'Montserrat', sans-serif //'ZCOOL KuaiLe', sans-serif

// media breakpoint
$x-small : 575px
$small : 768px
$medium : 992px
$large : 1200px
$x-large : 1400px
$xx-large : 1400px

