.home
    background-color: transparent
    background-image: url('../img/home_bg.jpg')
    background-attachment:  fixed
    background-repeat: no-repeat
    background-size: cover
    background-position: center
    background-blend-mode: color-burn
    backdrop-filter: blur(50px)
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    min-height: 90vh

    h1
        text-align: center

    img
        max-height: 50vh
        max-width: 100%
        backdrop-filter: blur(3px)
        margin-bottom: 1rem
        

        