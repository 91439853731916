header
    width: 100%
    height: 10vh

nav
    min-height: 100%
    img
       min-height: 60px 
       min-width: 60px
       margin-right: 1rem
       
       top: 0%
       left: 0
       
a
    display: flex
    align-items: center
    h1
        font-size: 1.5rem



.bg-primary
    background-color: $primary !important
